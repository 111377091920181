.free-gift-header {
  text-align: center;
  padding: 86px 48px 0 48px;
}

.icon-wrapper {
  display: inline-block;
  margin-bottom: 16px;
}

.gift-icon {
  color: var(--color-purple);
}

.main-heading {
  font-size: 32px;
  color: var(--color-blue);
  font-weight: 700;
  margin-bottom: 16px;
}

.highlight-text {
  color: var(--color-purple);
}

.subheading {
  color: var(--color-gray);
  margin-bottom: 32px;
}

.highlight-bold {
  font-weight: 600;
}

.call-to-action {
  color: var(--color-purple);
  margin-bottom: 0px;
}
