@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

:root {
  --color-navy: #1a1a3a;
  --color-light-gray: #ccd0d5;
  --color-primary: #b277ed;
  --color-purple: #b46ef9;
  --color-dark-purple: #9461c8;
  --color-light-purple: #f9f3ff;
  --color-error: #e60023;
  --color-background: #ffffff;
  --color-white: #ffffff;
  --color-disabled: #777;
  --color-blue: #003366;
  --color-gray: #667085;
  --color-super-light-gray: #f5f5f5;
  --color-default-background: #f9fafb;
  --color-grey-700: #475467;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  /* background-color: var(--color-white); */
  color: var(--color-navy);
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  color: inherit;
}

h2 {
  color: var(--color-blue);
  font-weight: 500;
  font-size: 32px;
}

h1 {
  font-weight: 500;
  font-size: 20px;
}

h3 {
  /* font-weight: 600; */
  font-size: 24px;
}

p {
  color: var(--color-gray);
  /* font-weight: 500; */
  font-size: 14px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 16px;
}

.mt-4 {
  margin-top: 24px;
}

.mt-5 {
  margin-top: 32px;
}

.mr-1 {
  margin-right: 16px;
}

.ml-1 {
  margin-left: 16px;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 16px;
}

.mb-4 {
  margin-bottom: 24px;
}

.mb-5 {
  margin-bottom: 32px;
}

.pt-1 {
  padding-top: 4px;
}

.pt-2 {
  padding-top: 8px;
}

.pt-3 {
  padding-top: 16px;
}

.pt-4 {
  padding-top: 24px;
}

.pt-5 {
  padding-top: 32px;
}

.pb-1 {
  padding-bottom: 4px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pb-3 {
  padding-bottom: 16px;
}

.pb-4 {
  padding-bottom: 24px;
}

.pb-5 {
  padding-bottom: 32px;
}

.section-spacing {
  margin-bottom: 50px;
}

button {
  cursor: pointer;
}

input,
textarea,
button {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.error-message {
  color: var(--color-error);
  font-size: 14px;
  margin-top: 5px;
  display: block;
  text-align: left;
}

.border-error {
  border-color: var(--color-error);
}

.text-field-input:focus {
  outline: none;
  border-color: var(--color-purple);
  box-shadow: 0 0 0 2px rgba(167, 139, 250, 0.2);
}

.italic-text {
  font-style: italic;
}

.highlight-dark-text {
  color: var(--color-dark-purple);
}

.highlight-text {
  color: var(--color-purple);
}

.flex {
  display: flex;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.app-badges {
  display: flex;
  gap: 26px;
}

.app-badge {
  height: 48px;
  width: 128px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 28px;
  }

  p {
    font-size: 16px;
  }

  .promo-image img {
    width: 100%;
    height: auto;
  }

  .text-center {
    text-align: center;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-light-gray);
  margin: 16px 0;
  opacity: 0.5;
}

.main-heading {
  font-size: 32px;
  color: var(--color-blue);
  font-weight: 700;
  margin-bottom: 16px;
}

body {
  margin: 0;
  background-color: var(--color-default-background);
}

:root {
  --font-family-inter: "Inter", sans-serif;
  --font-family-montserrat: "Montserrat", sans-serif;

  /* Display Styles */
  --display-2xl-font-size: 56px;
  --display-2xl-line-height: 62px;
  --display-2xl-letter-spacing: -0.06em;

  --display-xl-font-size: 40px;
  --display-xl-line-height: 48px;
  --display-xl-letter-spacing: -0.05em;

  --display-lg-font-size: 36px;
  --display-lg-line-height: 44px;
  --display-lg-letter-spacing: -0.05em;

  --display-md-font-size: 32px;
  --display-md-line-height: 42px;
  --display-md-letter-spacing: -0.05em;

  --display-sm-font-size: 30px;
  --display-sm-line-height: 42px;
  --display-sm-letter-spacing: -0.05em;

  --display-xs-font-size: 24px;
  --display-xs-line-height: 34px;
  --display-xs-letter-spacing: -0.03em;

  /* Text Styles */
  --text-xl-font-size: 20px;
  --text-xl-line-height: 30px;
  --text-xl-letter-spacing: -0.03em;

  --text-lg-font-size: 18px;
  --text-lg-line-height: 28px;
  --text-lg-letter-spacing: -0.02em;

  --text-md-font-size: 16px;
  --text-md-line-height: 24px;
  --text-md-letter-spacing: -0.01em;

  --text-s-font-size: 14px;
  --text-s-line-height: 20px;
  --text-s-letter-spacing: -0.01em;

  --text-xs-font-size: 12px;
  --text-xs-line-height: 25px;
  --text-xs-letter-spacing: 0;

  /* Montserrat Text Styles */
  --montserrat-md-font-size: 16px;
  --montserrat-md-line-height: 29px;
  --montserrat-md-letter-spacing: 0;

  --montserrat-small-font-size: 14px;
  --montserrat-small-line-height: 26px;
  --montserrat-small-letter-spacing: 0;

  --montserrat-xs-font-size: 12px;
  --montserrat-xs-line-height: 22px;
  --montserrat-xs-letter-spacing: 0;

  /* Font Weights */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;

  /* Font Styles */
  --font-style-italic: italic;
}

/* Display Styles */
.display-2xl {
  font-family: var(--font-family-inter);
  font-size: var(--display-2xl-font-size);
  line-height: var(--display-2xl-line-height);
  letter-spacing: var(--display-2xl-letter-spacing);
}

.display-xl {
  font-family: var(--font-family-inter);
  font-size: var(--display-xl-font-size);
  line-height: var(--display-xl-line-height);
  letter-spacing: var(--display-xl-letter-spacing);
}

.display-lg {
  font-family: var(--font-family-inter);
  font-size: var(--display-lg-font-size);
  line-height: var(--display-lg-line-height);
  letter-spacing: var(--display-lg-letter-spacing);
}

.display-md {
  font-family: var(--font-family-inter);
  font-size: var(--display-md-font-size);
  line-height: var(--display-md-line-height);
  letter-spacing: var(--display-md-letter-spacing);
}

.display-sm {
  font-family: var(--font-family-inter);
  font-size: var(--display-sm-font-size);
  line-height: var(--display-sm-line-height);
  letter-spacing: var(--display-sm-letter-spacing);
}

.display-xs {
  font-family: var(--font-family-inter);
  font-size: var(--display-xs-font-size);
  line-height: var(--display-xs-line-height);
  letter-spacing: var(--display-xs-letter-spacing);
}

/* Text Styles */
.text-xl {
  font-family: var(--font-family-inter);
  font-size: var(--text-xl-font-size);
  line-height: var(--text-xl-line-height);
  letter-spacing: var(--text-xl-letter-spacing);
}

.text-lg {
  font-family: var(--font-family-inter);
  font-size: var(--text-lg-font-size);
  line-height: var(--text-lg-line-height);
  letter-spacing: var(--text-lg-letter-spacing);
}

.text-md {
  font-family: var(--font-family-inter);
  font-size: var(--text-md-font-size);
  line-height: var(--text-md-line-height);
  letter-spacing: var(--text-md-letter-spacing);
}

.text-s {
  font-family: var(--font-family-inter);
  font-size: var(--text-s-font-size);
  line-height: var(--text-s-line-height);
  letter-spacing: var(--text-s-letter-spacing);
}

.text-xs {
  font-family: var(--font-family-inter);
  font-size: var(--text-xs-font-size);
  line-height: var(--text-xs-line-height);
  letter-spacing: var(--text-xs-letter-spacing);
}

/* Text Styles for Montserrat */
.montserrat-md {
  font-family: var(--font-family-montserrat);
  font-size: var(--montserrat-md-font-size);
  line-height: var(--montserrat-md-line-height);
  letter-spacing: var(--montserrat-md-letter-spacing);
}

.montserrat-sm {
  font-family: var(--font-family-montserrat);
  font-size: var(--montserrat-small-font-size);
  line-height: var(--montserrat-small-line-height);
  letter-spacing: var(--montserrat-small-letter-spacing);
}

.montserrat-xs {
  font-family: var(--font-family-montserrat);
  font-size: var(--montserrat-xs-font-size);
  line-height: var(--montserrat-xs-line-height);
  letter-spacing: var(--montserrat-xs-letter-spacing);
}

/* Font Weight Styles */
.medium {
  font-weight: var(--font-weight-medium);
}

.medium-italic {
  font-weight: var(--font-weight-medium);
  font-style: var(--font-style-italic);
}

.semibold {
  font-weight: var(--font-weight-semibold);
}

.standard-style {
  font-style: normal;
}

.italic {
  font-style: var(--font-style-italic);
}

.main-color {
  color: var(--color-purple);
}

.gray-text {
  color: var(--color-gray);
}

.blue-text {
  color: var(--color-blue);
}

.white-text {
  color: var(--color-white);
}

.black-text {
  color: black;
}

.primary-text {
  color: var(--color-primary);
}

.success-text {
  color: green;
}

.failure-text {
  color: red;
}

.grey-700 {
  color: var(--color-grey-700);
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}