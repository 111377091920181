/* AddToCartModal.css */
.add-to-card-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.add-to-card-modal-content {
  /* padd */
  background-color: white;
  /* padding-top: 24px; */
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 42px;
  padding-bottom: 32px;
  border-radius: 16px;
  text-align: center;
  width: 368px;
  position: relative;
}

.add-to-card-modal-close-btn {
  position: absolute;
  padding-top: 16px;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
}

.add-to-card-modal-check-icon img {
  width: 80px;
  margin-bottom: 16px;
}

.add-to-card-modal-product {
  margin-bottom: 26px;
}

.primary-button {
  opacity: 1;
}

.add-to-card-modal-secondary-btn {
  background-color: transparent;
  color: var(--color-primary);
  cursor: pointer;
  border: none;
}

@media (max-width: 764px) {
  .add-to-card-modal-overlay {
    justify-content: flex-end;
    align-items: flex-end;
  }

  .add-to-card-modal-content {
    width: 100%;
    border-radius: 16px 16px 0 0; /* Zaokrąglenie tylko górnych rogów */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .add-to-card-modal-check-icon img {
    width: 60px;
  }

  .add-to-card-modal-close-btn {
    top: 8px;
    right: 8px;
  }

  .add-to-card-modal-secondary-btn {
    padding-bottom: 24px;
  }
}
