.featured-article-background {
  /* background-color: #F2F4F7; */
}

.featured-article {
  max-width: 1440px;
  height: 670px;
  margin: auto;
  display: flex;
  width: 100%;
}

.featured-article__left,
.featured-article__right {
  width: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.featured-article__right {
  padding-left: 24px;
}

.featured-article-subarticle {
  /* background-color: #F2F4F7; */
  max-width: 1440px;
  display: flex;
  align-items: center;
  padding: 4px;
  /* padding-left: 20px; */
  border-radius: 12px;
}

.featured-article-subarticle__image-container {
  position: relative;
  flex: 0 0 auto;
}

.featured-article-subarticle__image {
  width: 160px;
  height: 160px;
  border-radius: 12px;
  object-fit: cover;
}

.featured-article-subarticle__label {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: white;
  padding: 6px 8px;
  border-radius: 12px;
}

.featured-article-subarticle__content {
  flex: 1;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.featured-article-subarticle__title {
  margin: 0;
}

.featured-article-subarticle__link {
  text-decoration: none;
}

.featured-article-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.featured-article-cell-mobile {
  display: flex;
  flex-direction: column;
  max-height: 600px;
  max-width: 80vw; /* Maksymalna szerokość w procentach widoku */
  flex: none;
  scroll-snap-align: start;
  margin: 0 auto;
}

.featured-article-cell {
  display: flex;
  flex-direction: column;
  /* background-color: #f9f9f9; */
  overflow: hidden;
  max-width: 100%;
  /* height: 460px; */
  margin: 0 auto;
}

.featured-article-image-container-mobile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 232px;
  overflow: hidden;
  border-radius: 16px;
}

.featured-article-image-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 400px;
  overflow: hidden;
  /* border-radius: 16px; */
}

.featured-article-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 16px; */
}

.featured-article-label-tag {
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 4px 12px;
  font-size: 14px;
  color: var(--color-blue);
  background-color: #f0f4f8;
  border: 1px solid #d3d3d3;
  border-radius: 12px;
}

.featured-article-title-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

.featured-article-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.featured-article-title {
  color: var(--color-blue);
}

.featured-article-description {
  color: var(--color-gray);
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.featured-article-arrow {
  align-self: flex-end;
  color: var(--color-primary);
  margin-left: auto;
  margin-bottom: auto;
}

.featured-article-horizontal-list {
  display: flex;
  flex-direction: row;
  gap: 32px;
  overflow-x: auto;
  padding: 20px;
}
