.plan-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 60px 200px;
}

@media (max-width: 868px) {
  .plan-container {
    align-items: flex-start;
    text-align: left;
    padding: 40px 20px;
  }
}
