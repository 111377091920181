.programs-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  transition: background-color 0.3s ease;
  width: 416px;
  height: 550px;
  text-align: left;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.programs-top-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.programs-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.programs-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 2;
}

.programs-image {
  width: 347px;
  right: 0;
  bottom: 0;
  position: absolute;
  /* height: 347px; */
  height: auto;
  object-fit: cover;
  object-position: bottom;
  transition: transform 0.3s ease;
}

.programs-content {
  display: flex;
  justify-content: left;
  align-items: start;
  width: 100%;
  padding: 16px;
  position: relative;
  z-index: 3;
}

.programs-text-container {
  padding: 16px;
  z-index: 3;
}

.arrow-icon {
  margin-top: 16px;
  margin-right: 2px;
  color: var(--color-blue);
  transition: color 0.2s ease;
  z-index: 3;
}

.programs-cell:hover .arrow-icon {
  color: var(--color-purple);
}

.programs-cell:hover .programs-image {
  transform: translateY(-14px) scale(1.07);
}
