.product-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 16px;
  justify-items: center;
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 8px;
}

@media (max-width: 768px) {
  .product-container {
    grid-template-columns: 1fr;
  }
}

.product-container > * {
  flex-shrink: 0;
  margin-right: 16px;
}

@media (max-width: 1404px) {
  .product-container {
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 1404px) {
  .product-container {
    justify-content: flex-start;
  }
}
