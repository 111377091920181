.login-container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.login-left {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 32px;
}

.logo-container {
  top: 8px;
  left: 8px;
}

.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* 
.form-cntr {
  max-width: 640px;
  margin: auto;
  padding: 0 auto;;
} */

.login-form {
  width: 360px;
}

.logo-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-not-remember-password {
  color: var(--color-primary);
  width: 100%;
  text-align: right;
}

.login-right {
  width: 100%;
  height: 100vh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--color-super-light-gray);
  border-top-left-radius: 64px;
  border-bottom-left-radius: 64px;
  position: relative;
}

.login-footer a {
  color: var(--color-primary);
  text-decoration: none;
}

.app-promo-section {
  position: absolute;
  width: 578px;
  bottom: 1%;
  left: 1%;
  margin: 32px;
}

.promo-image {
  /* width: 100%; */
  height: 60%;
  object-fit: cover;
  z-index: 1;
  overflow: visible;
}

.logo {
  height: 64px;
  padding-left: 24px;
  padding-top: 24px;
  justify-content: left;
  align-items: left;
}

.form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 16px;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 16px 0;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--color-light-gray);
}

.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}

.google-button,
.apple-button {
  width: 100%;
  padding: 12px;
  background-color: #f5f7fa;
  border: 1px solid var(--color-light-gray);
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-button img,
.apple-button img {
  width: 20px;
  margin-right: 8px;
}

.login-footer {
  text-align: center;
}

.app-store-buttons {
  display: flex;
  justify-content: left;
  gap: 20px;
}

.app-badge {
  width: 120px;
  cursor: pointer;
}

.partners-badge {
  margin-top: 16px;
}

@media (max-width: 1300px) {
  .login-container {
    padding-bottom: 24px;
  }

  .login-left {
    width: 100%;
    padding: 0px;
  }

  .login-right {
    display: none;
  }

  .login-form-container {
    margin-top: 82px;
  }
  /* .form-container {
    display: grid;
    justify-content: top;
    align-items: top;
    flex-direction: column;
  } */
}
