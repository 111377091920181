.detail-product-parameters {
  margin: 20px 0;
  padding: 12px;
  /* background-color: rebeccapurple; */
  /* border-radius: 8px; */
}

.detail-product-parameters-title {
  background-color: #fcfaff;
  margin-bottom: 10px;
  border-radius: 8px;
}

.detail-product-parameters-items {
  list-style-type: none;
  padding-left: 0;
}

.detail-product-parameters-item {
  font-size: 16px;
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.detail-product-parameters-item-desc {
  margin-right: 16px;
}
