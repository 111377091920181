.footer {
  background-color: white;
  padding-top: 32px;
  margin-top: 32px;
  padding-bottom: 40px;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  margin-left: 32px;
  margin-right: 32px;
  gap: 40px;
}

.footer-left {
  max-width: 45%;
}

.footer-left .highlight {
  color: var(--color-purple);
}

.footer-left address,
.footer-left p {
  color: var(--color-gray);
}

.footer-left a {
  color: var(--color-blue);
  text-decoration: none;
}

.footer-center {
  max-width: 200px;
  gap: 20px;
}

.footer-center h4 {
  color: var(--color-blue);
  margin-bottom: 16px;
}

.footer-center ul {
  list-style: none;
  padding: 0;
}

.footer-center ul li {
  margin-bottom: 8px;
}

.footer-center ul li a {
  color: var(--color-gray);
  text-decoration: none;
}

.footer-right {
}

.footer-center-colored {
  color: var(--color-blue);
}

.footer-right h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-blue);
}

.footer-social img,
.footer-payment img {
  width: 40px;
  margin-right: 16px;
}

.footer-center ul {
  list-style: none;
  flex-direction: column;
}

.footer-center ul li {
  margin-bottom: 16px;
}

.footer-partners img {
  width: 120px;
}

.footer-payment .payment-icons {
  display: flex;
  gap: 8px;
}

.footer-social a,
.footer-social img {
  height: 36px;
  width: 36px;
}

.footer-right .footer-partners img {
  width: 80px;
}

.footer-right .footer-payment img {
  width: 40px;
}

@media (max-width: 1200px) {
  .footer-content {
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    max-width: 100%;
  }
}
