.latest-article-grid {
  display: grid;
  gap: 64px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 64px;
  padding-right: 64px;
}

@media (max-width: 1440px) {
  .latest-article-grid {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1208px) {
  .latest-article-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .latest-article-grid {
    gap: 32px;
    padding: 16px;
    grid-template-columns: 1fr;
  }
}
