.latest-article-cell {
  display: flex;
  flex-direction: column;
  /* background-color: #f9f9f9; */
  overflow: hidden;
  max-width: 416px;
  /* height: 460px; */
  margin: 0 auto;
}

.latest-article-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 300px;
  overflow: hidden;
  border-radius: 16px;
}

.latest-article-image {
  max-height: 300px;
  border-radius: 16px;
}

.latest-article-label-tag {
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 4px 12px;
  font-size: 14px;
  color: var(--color-blue);
  background-color: #f0f4f8;
  border: 1px solid #d3d3d3;
  border-radius: 16px;
}

.latest-article-title-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

.latest-article-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.latest-article-title {
  color: var(--color-blue);
}

.latest-article-description {
  color: var(--color-gray);
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.latest-article-arrow {
  align-self: flex-end;
  color: var(--color-primary);
  margin-left: auto;
  margin-bottom: auto;
}

@media (max-width: 720px) {
  .latest-article-image {
    max-width: 95%;
  }
}
