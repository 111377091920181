.tab-menu-container {
  width: 100%;
  background-color: white;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
}

.custom-tabmenu-icon {
  width: 22px;
  height: 22px;
  margin-right: 12px;
  transition: transform 0.2s ease-in-out;
}

.p-tabmenuitem {
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-tabmenu-icon.active {
  transform: scale(1.3);
  filter: brightness(1.3);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: var(--color-purple);
  width: 100%;
  justify-content: center;
}

.p-tabmenuitem .pi {
  transition: transform 0.2s ease-in-out;
}

.p-tabmenuitem.p-highlight .pi {
  transform: scale(1.4);
  color: var(--color-blue);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: var(--color-purple);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .pi {
  color: var(--color-purple);
}

.p-tabmenu-ink-bar {
  display: none;
  z-index: 1;
  color: blueviolet;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: white;
}

@media (max-width: 1200px) {
  .tab-menu-container {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    border-color: var(--color-purple);
    width: 100%;
    justify-content: center;
  }

  .p-tabmenuitem {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
