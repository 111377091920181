.text-field {
  width: 100%;
}

.text-field-title {
  display: block;
  margin-bottom: 8px;
  color: var(--color-blue);
  text-align: left;
}

.text-field-input {
  width: 100%;
  padding: 12px 16px;
  color: var(--color-blue);
  border: 1px solid var(--color-light-gray);
  border-radius: 8px;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;
  background-color: var(--color-white);
}

.text-field-input::placeholder {
  color: var(--color-gray);
  opacity: 1;
}

.text-field-input:focus {
  outline: none;
  border-color: var(--color-purple);
  box-shadow: 0 0 0 2px rgba(167, 139, 250, 0.2);
}

.text-field-error {
  border-color: var(--color-error);
}

.text-field-error-message {
  color: var(--color-error);
  margin-top: 0px;
  display: block;
  text-align: left;
}

.border-error {
  border-color: var(--color-error);
}
