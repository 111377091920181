@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 48px;
  right: 0;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 230px;
  margin-top: 8px;
  animation: slideDown 0.3s ease forwards;
}

.dropdown-menu.slide-up {
  animation: slideUp 0.3s ease forwards;
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.dropdown-menu li img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.dropdown-menu li {
  text-align: center;
  padding: 15px 20px;
  font-size: 16px;
  color: var(--color-blue);
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.dropdown-menu li i {
  margin-right: 10px;
  font-size: 24px;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu li.logout {
  color: var(--color-error);
}
