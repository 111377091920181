.example-workout-program {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.workout-list {
  flex: 1;
  max-width: 40%;
}

.workout-item {
  background-color: var(--color-white);
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  border-left: 4px solid transparent;
  transition: all 0.3s ease;
  overflow: hidden;
}

.workout-item h3 {
  color: var(--color-blue);
}

.workout-item.active {
  border-left-color: var(--color-purple);
}

.workout-item.inactive {
  border-left-color: var(--color-light-gray);
}

.workout-description {
  margin-top: 12px;
  margin-bottom: 16px;
  transition:
    max-height 0s ease,
    opacity 0.5s ease;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  background-color: f5f5f5;
}

.workout-item.active .workout-description {
  max-height: 600px;
  opacity: 1;
}

.workout-description ul {
  padding-left: 20px;
  list-style: disc;
  margin: 10px 0;
}

.watch-fragment {
  color: var(--color-purple);
  margin-top: 10px;
}

.workout-video {
  flex: 2;
  max-width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

video {
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}
