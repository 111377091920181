.programs-list {
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  max-width: 1372px;
}

@media (max-width: 1372px) {
  .programs-list {
    justify-content: center;
  }
}

.programs-item-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.programs-item-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 300ms ease-in,
    transform 300ms ease-in;
}

.programs-item-exit {
  opacity: 1;
  transform: translateY(0);
}

.programs-item-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition:
    opacity 300ms ease-in,
    transform 300ms ease-in;
}
