.promo-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.promo-text {
  flex: 1;
  max-width: 50%;
  margin-right: 20px;
  margin-left: 32px;
}

.promo-text-container {
  width: 614px;
}

.promo-image {
  width: 40%;
  display: flex;
  justify-content: right;
}

.promo-image img {
  max-height: 500px;
  width: auto;
  height: auto;
}

.blue-text {
  color: var(--color-blue);
}

.promo-app-badges {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
}

.app-badge {
  width: 168px;
}

@media (max-width: 1200px) {
  .promo-section {
    flex-direction: column;
    padding: 16px;
    max-width: 100%;
    align-items: start;
  }

  .promo-text-container {
    /* justify-content: start; */
    width: 100%;
  }

  .promo-text {
    margin-left: 0px;
    max-width: 100%;
    text-align: left;
  }

  .promo-image {
    margin-top: 20px;
    width: 100%;
  }

  .promo-image img {
    width: 100%;
  }

  .app-store {
    content: url("../../../../public/socials/app-store-large.svg");
  }

  .google-play {
    content: url("../../../../public/socials/google-play-large.svg");
  }

  .app-badge {
    width: 100%;
  }

  .promo-app-badges {
    justify-content: center;
  }
}

@media (max-width: 762px) {
  .promo-text {
    margin-left: 16px;
    max-width: 100%;
  }

  .promo-text-container {
    width: 100%;
  }

  .app-badges {
    height: auto;
    width: 100%;
    gap: 16px;
  }

  .promo-promo-section {
    margin-left: 0px;
    margin-right: 0px;
  }

  .promo-app-badges {
    flex-direction: column;
  }
}
