.default-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  z-index: 1000;
}

.default-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 16px;
  max-width: 500px;
  width: 90%;
  box-sizing: border-box;
  text-align: center;
}
