.recommended-articles-container {
  overflow-x: auto;
  padding: 16px;
  max-width: 100%;
  white-space: nowrap;
}

.recommended-articles-grid {
  display: inline-flex;
  gap: 24px;
  max-width: 1380px;
  margin: 0 auto;
}

.recommended-article-cell {
  flex: 0 0 416px;
  width: 416px;
}

@media (min-width: 1380px) {
  .recommended-articles-container {
    overflow-x: hidden;
  }
  .recommended-articles-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
}
