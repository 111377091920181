.DesktopTopNavigationBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: white;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  height: 64px;
}

.navbar-logo {
  display: flex;
  align-self: center;
}

.navbar-logo img {
  margin: 0;
  top: 50%;
  left: 50%;
  height: 54px;
  margin: -20px 0 0 0;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 42px;
  padding: 24px;
  position: relative;
}

.navbar-links a {
  text-decoration: none;
  color: var(--color-blue);
}

.navbar-links a:hover {
  color: var(--color-primary);
}

.MobileTopNavigationBar {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: white;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  height: 64px;
}

.NavLinks ul {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  top: 50px;
  right: 0;
  gap: 8px;
  width: 100%;
  z-index: 1000;
  background: white;
  padding-bottom: 32px;
}

nav ul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

nav ul li {
  display: inline-block;
  padding-top: 32px;
  margin-right: 32px;
}

nav ul li a {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

nav ul li a:hover {
  color: white;
  transition: 0.3s;
}

.cart-icon-container {
  position: relative;
}

.cart-link {
  position: relative;
  display: inline-block;
  color: inherit;
}

.bag-badge {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: var(--color-blue);
  border-radius: 50%;
  padding: 4px 10px;
  color: white;
}

/* .cart-user-not-found {
    display: inline-block;
    color: inherit;
} */

@media (max-width: 768px) {
  .DesktopTopNavigationBar {
    display: none;
  }

  .MobileTopNavigationBar {
    display: flex;
  }

  .cart-link {
    position: absolute;
    right: 66px;
  }

  .cart-user-not-found {
    position: absolute;
    right: 16px;
    top: 20px;
  }

  .bag-badge {
    width: 24px;
    height: 24px;
    padding-left: 9px;
    padding-top: 0px;
  }
}
