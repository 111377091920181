.metamorphoses-card {
  position: relative;
  width: 425px;
  height: 570px;
  border-radius: 16px;
  overflow: hidden;
}

.metamorphoses-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.metamorphoses-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-blue);
  background: linear-gradient(0deg, var(--color-blue) 0%, transparent 100%);
  padding: 26px;
  text-align: left;
  border-radius: 0 0 16px 16px;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.metamorphoses-name {
  color: var(--color-purple);
}

.metamorphoses-description {
  color: var(--color-white);
  font-weight: 200;
  padding-bottom: 26px;
}
