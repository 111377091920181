.base-container {
  background-color: #0c1328;
  padding: 48px;
}

.text-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 26px;
  gap: 24px;
  margin-bottom: 16px;
}

.text-container P {
  color: var(--color-purple);
  font-size: 16px;
}

.text-container h1 {
  color: white;
  font-size: 36px;
}

.scrollable-container {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px;
  scroll-behavior: smooth;
  max-width: 100vw;
  cursor: grab;
}

.scrollable-container.active {
  cursor: grabbing;
}

.scrollable-container::-webkit-scrollbar {
  display: none;
}

.scrollable-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.metamorphoses-card {
  flex: 0 0 auto;
  width: 500px;
  height: 660px;
  border-radius: 16px;
}

@media (max-width: 762px) {
  .scrollable-container {
    padding: 0px;
  }
}
