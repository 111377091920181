.review-card {
  flex: 0 0 auto;
  width: 230px; /* Fixed width */
  height: 315px; /* Fixed height */
  background-color: var(--color-white);
  border-radius: 16px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stars {
  color: #f9b23d;
  font-size: 18px;
}

.review-text {
  color: var(--color-navy);
  line-height: 1.5;
}

.review-footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.review-name {
  color: var(--color-navy);
  /* font-size: 16px; */
  font-weight: bold;
}
