.secured-textfield-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

/* .secured-textfield-label {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    color: var(--color-blue);
    text-align: left;
  } */

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.secured-input {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid var(--color-light-gray);
  border-radius: 8px;
  background-color: var(--color-white);
  outline: none;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;
}

.secured-input:focus {
  outline: none;
  border-color: var(--color-purple);
  box-shadow: 0 0 0 2px rgba(167, 139, 250, 0.2);
}

.toggle-password-visibility {
  position: absolute;
  right: 16px;
  top: 20%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.toggle-password-visibility img {
  width: 20px;
  height: 20px;
}

.border-error {
  border-color: var(--color-error);
}

.text-field-input:focus {
  outline: none;
  border-color: var(--color-purple);
  box-shadow: 0 0 0 2px rgba(167, 139, 250, 0.2);
}
