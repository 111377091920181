/* General Styling for the Product Details Screen */
.product-details-screen {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 64px;
  margin-bottom: 64px;
}

/* Main Section with Image and Info */
.product-details-screen-main-section {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  margin-bottom: 40px;
}

.product-details-screen-image-section {
  flex: 1;
}

.product-details-screen-image {
  width: 100%;
  max-width: 450px;
  border-radius: 8px;
}

.product-details-screen-info-section {
  flex: 1;
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.product-details-screen-rating {
  display: flex;
  align-items: center;
  /* font-size: 1rem; */
  /* margin-bottom: 20px; */
}

.product-details-screen-star {
  color: gold;
  margin-right: 10px;
}

.product-details-screen-quantity-selector {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.product-details-screen-quantity-input {
  width: 60px;
  margin-left: 10px;
  padding: 5px;
  /* font-size: 1rem; */
}

.product-details-screen-add-to-cart-button {
  background-color: #9b59b6;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  /* font-size: 1rem; */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-details-screen-add-to-cart-button:hover {
  background-color: #8e44ad;
}

/* Description Section */
.product-details-screen-description-section {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.product-details-screen-description-section a {
  color: #9b59b6;
  text-decoration: none;
}

.product-details-screen-description-section a:hover {
  text-decoration: underline;
}
