.subscriptions-content {
  padding: 32px 32px 32px 32px;
  display: flex;
  background-color: var(--color-white);
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}

.subscription-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
}

.subscription-details {
  display: flex;
  gap: 40px;
}

.subscription-detail {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.subscription-detail .value {
  font-weight: bold;
  color: var(--color-navy);
}

.cancel-subscription {
  background: none;
  color: var(--color-error);
  border: none;
  font-weight: bold;
  cursor: pointer;
  margin-left: auto;
}

.change-subscription-content {
  padding-top: 32px;
}

@media (max-width: 768px) {
  .subscription-header {
    display: inline;
  }

  .subscription-details {
    display: inline;
  }
}
