.subscription-plan {
  border: 1px solid var(--color-super-light-gray);
  border-radius: 16px;
  margin-bottom: 24px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease,
    border-color 0.3s ease;
  position: relative;
}

.subscription-plan.selected {
  border-color: var(--color-purple);
}

.plan-header {
  background-color: var(--color-default-background);
  padding: 18px 24px 18px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 16px;
  overflow: auto;
  border-top-right-radius: 16px;
  transition: background-color 0.3s ease;
}

.plan-header.selected {
  background-color: var(--color-primary);
  color: white;
}

.plan-header-title {
  display: flex;
  gap: 20px;
}

.subscription-label {
  background-color: white;
  padding: 2px 16px;
  border-radius: 20px;
}

.checkmark {
  top: 16px;
  right: 0px;
  font-size: 24px;
  color: white;
  background-color: var(--color-purple);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-body {
  padding: 24px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.plan-price {
  font-weight: bold;
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
  margin-top: 6px;
}

.period {
  margin-left: 8px;
}

.total-cost {
  color: var(--color-gray);
  margin-bottom: 20px;
}

.plan-details {
  display: flex;
  gap: 25px;
  border-top: 1px solid var(--color-super-light-gray);
  padding-top: 20px;
}

.detail-item {
  gap: 8px;
  display: flex;
  align-items: center;
}

.detail-item span {
  color: var(--color-purple);
  margin-right: 8px;
}

.subscription-plan:hover {
  background-color: var(--color-default-background);
}

@media (max-width: 768px) {
  .subscription-label {
    display: none;
  }

  .plan-details {
    display: block;
  }

  .detail-item {
    padding-bottom: 12px;
  }
}
