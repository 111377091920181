.basket-header-container {
  margin: 32px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 12px;
}

.basket-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.basket-content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.basket-main-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.basket-list-container {
  flex: 2;
}

.basket-summary-container {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

@media (max-width: 944px) {
  .basket-main-container {
    flex-direction: column;
    align-items: center;
  }

  .basket-list-container,
  .basket-summary-container {
    width: 100%;
  }

  .basket-summary-container {
    margin-top: 24px;
  }

  .basket-content-container {
    padding: 0px;
  }

  .basket-header-container {
    margin-left: 32px;
  }
}

@media (max-width: 600px) {
  .basket-summary-container {
    width: 100%;
    margin-top: 16px;
  }
}
