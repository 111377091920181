.password-reset-container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.password-reset-left {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 32px;
}

.logo-container {
  top: 8px;
  left: 8px;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* 
  .form-cntr {
    max-width: 640px;
    margin: auto;
    padding: 0 auto;;
  } */

.password-reset-form {
  width: 360px;
}

.logo-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.password-reset-not-remember-password {
  color: var(--color-primary);
  width: 100%;
  text-align: right;
}

.password-reset-right {
  width: 100%;
  height: 100vh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--color-super-light-gray);
  border-top-left-radius: 64px;
  border-bottom-left-radius: 64px;
  position: relative;
}

.password-reset-footer a {
  color: var(--color-primary);
  text-decoration: none;
}

.app-promo-section {
  position: absolute;
  margin: 32px;
  width: 578px;
  bottom: 0px;
  left: 1%px;
}

.promo-image {
  /* width: 100%; */
  /* height: 80%; */
  object-fit: cover;
  overflow: hidden;
}

.logo {
  height: 64px;
  padding-left: 24px;
  padding-top: 24px;
  justify-content: left;
  align-items: left;
}

.form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 16px;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 16px 0;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--color-light-gray);
}

.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}

.google-button,
.apple-button {
  width: 100%;
  padding: 12px;
  background-color: #f5f7fa;
  border: 1px solid var(--color-light-gray);
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-button img,
.apple-button img {
  width: 20px;
  margin-right: 8px;
}

.password-reset-footer {
  text-align: center;
}

.app-store-buttons {
  display: flex;
  justify-content: left;
  gap: 20px;
}

.app-badge {
  width: 120px;
  cursor: pointer;
}

.partners-badge {
  margin-top: 16px;
}

@media (max-width: 1300px) {
  .password-reset-container {
    padding-bottom: 24px;
  }

  .password-reset-left {
    width: 100%;
    padding: 0px;
  }

  .password-reset-right {
    display: none;
  }
}
