.basket-item-cell {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 180px;
  padding: 16px 0;
}

.basket-item-image img {
  width: 160px;
  height: 160px;
  border-radius: 8px;
  object-fit: cover;
}

.basket-item-info {
  flex-grow: 2;
  gap: 24px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.basket-item-description {
  max-lines: 1;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.basket-item-price-in-description {
  display: none;
}

.basket-item-quantity {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.basket-quantitiy-width {
  width: 36px;
  text-align: center;
}

.quantity-btn {
  background-color: var(--color-super-light-gray);
  border: 1px solid white;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: var(--color-primary);
}

.quantity-btn-plus {
  background-color: var(--color-primary);
  color: white;
}

.basket-item-price {
  align-self: flex-start;
  width: 164px;
  padding-top: 20px;
  padding-right: 16px;
  text-align: right;
}

@media (max-width: 764px) {
  .basket-item-price {
    display: none;
  }
}
@media (max-width: 1220px) {
  .basket-item-description {
    width: 100px;
  }
}

@media (max-width: 1095px) {
  .basket-item-price {
    display: none;
  }

  .basket-item-price-in-description {
    display: inline;
  }

  .basket-item-info {
    gap: 16px;
  }
}

@media (max-width: 945px) {
  .basket-item-description {
    /* width: 100%; */
  }
}
