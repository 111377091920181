.blog-page-content-view {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.blog-page-content-view-spacer {
  height: 38px;
}
