.orders-content {
  padding: 24px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.orders-content h2 {
  color: var(--color-blue);
  margin-bottom: 24px;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
}

.orders-table th,
.orders-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #eaecf0;
}

.orders-table th {
}

.orders-table td {
  color: var(--color-blue);
}

.product-image {
  width: 10px;
  height: 10px;
  /* object-fit: cover; */
  border-radius: 8px;
}

.details-button {
  background: none;
  border: none;
  cursor: pointer;
}

.details-button img {
  width: 24px;
  height: 24px;
  color: var(--color-purple);
  transition: transform 0.2s ease;
}

.details-button img:hover {
  transform: scale(1.06);
}

table {
  width: 100%;
  border-collapse: collapse;
}

td {
  padding: 32px;
}

td.product {
  width: 86px;
  /* height: 20px */
}

td.order-id {
  width: 50%;
}

td.details-array {
  width: 64px;
}

@media (max-width: 1048px) {
  .orders-content {
    padding: 16px;
  }

  .orders-table th,
  .orders-table td {
    padding: 8px;
  }

  td.order-id {
    width: 28%;
  }
}

@media (max-width: 748px) {
  td.order-id {
    width: 10%;
  }

  td.product {
    width: 64px;
  }
}
