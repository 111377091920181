.blog-header-mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 24px;
}

.blog-rounded-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 10px;
  padding-top: 10px;
  border: 2px solid #d3d3d3;
  border-radius: 30px;
  background-color: white;
  margin-bottom: 16px;
  /* margin-left: auto; */
  margin-right: auto;
}

.blog-rounded-tag-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 10px;
  padding-top: 10px;
  border: 2px solid #d3d3d3;
  border-radius: 30px;
  background-color: white;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
}

.mobile-blog-header-text-content {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.mobile-blog-header-padding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  max-width: 80%;
  margin: auto;
}

.mobile-blog-header-small-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.mobile-blog-header-small-box {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-bottom: 32px;
  gap: 8px;
}

.blog-bg-container {
  background-color: #f2f4f7;
}

.blog-header-container {
  display: flex;
  max-width: 1440px;
  height: 470px;
  margin: 0 auto;
}

.blog-header-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-header-image {
  max-height: 100%;
  max-width: 100%;
}

.blog-header-right {
  flex: 1;
  display: flex;
}

.blog-header-padding {
  padding-left: 48px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.blog-header-title {
  margin-bottom: 20px;
}

.blog-header-small-box {
  display: flex;
  align-items: center;
}

.blog-header-small-image {
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.blog-header-text-content {
  display: flex;
  flex-direction: column;
}

.blog-header-small-title {
  margin: 0;
}
