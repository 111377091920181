.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.edit-profile-modal {
  background-color: white;
  width: 100%;
  max-width: 500px;
  padding: 24px;
  border-radius: 16px;
  z-index: 1001;
  position: relative;
  transform: scale(0.8);
  opacity: 0;
  animation: modalFadeIn 0.3s forwards;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}

.edit-profile-form {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--color-blue);
}

input {
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}

.input-group {
  display: flex;
  gap: 16px;
}

.save-button {
  width: 100%;
  padding: 16px;
  background-color: var(--color-purple);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.modal-closing {
  animation: modalFadeOut 0.3s forwards;
}

/* Animacje */
@keyframes fadeIn {
  from {
    opacity: 0;
    animation: modalFadeIn 0.3s forwards;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
  }
  
  .edit-profile-form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: 500;
    margin-bottom: 8px;
    color: var(--color-blue);
  }
  
  .input-field {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    border: 1px solid #EAECF0;
    border-radius: 8px;
  }
  
  .input-group {
    display: flex;
    gap: 16px;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
