.review-list-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content and buttons */
  position: relative;
}

.review-list {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px;
  scroll-behavior: smooth;
  max-width: 100vw;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 12px;
}

.scroll-btn {
  background: var(--color-white);
  border: 1px solid var(--color-light-gray);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scroll-btn:hover {
  background-color: var(--color-light-purple);
}

.scroll-btn:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(167, 139, 250, 0.2);
}

.scroll-btn svg {
  color: var(--color-blue);
}

.review-list::-webkit-scrollbar {
  display: none;
}

.review-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
