.training-cell {
  position: relative;
  width: 420px;
  height: 250px;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.training-cell img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.training-cell-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 51, 102, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: opacity 0.3s ease-in-out;
  border-radius: 16px;
}

.training-cell-subtitle {
  justify-content: center;
}

.time-background {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #a6a6a633;
  transition: opacity 0.3s ease-in-out;
  border-radius: 20px;
  padding: 10px 20px;
  margin-top: 10px;
}

.training-cell:hover img {
  transform: scale(1.1);
}

.training-cell:hover .training-cell-content .time-background {
  opacity: 0;
}

.training-cell-subtitle {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.training-cell-title,
.training-cell-subtitle {
  transition: opacity 0.3s ease-in-out;
  text-align: center;
}

.training-cell-subtitle svg {
  margin-right: 8px;
}

.training-cell-base {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.training-details-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.training-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 24px;
  background: rgba(0, 51, 102, 0.8);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.training-cell:hover .training-details {
  opacity: 1;
}

.training-details h1 {
  margin: 8px 0;
  font-size: 16px;
  color: white;
  display: flex;
}

.training-details snap {
  align-items: center;
}

.training-details svg {
  margin-right: 8px;
  color: var(--color-purple);
}

.training-cell-title,
.training-cell-subtitle {
  transition: opacity 0.3s ease-in-out;
}

.training-details {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  text-align: left;
}

.training-cell:hover .training-cell-title,
.training-cell:hover .training-cell-subtitle {
  opacity: 0;
}

.training-cell:hover .training-details {
  opacity: 1;
}

.icon {
  max-width: 20px;
  max-height: 20px;
  margin-right: 8px;
}
