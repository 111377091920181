.main-blog-screen-top-container {
  /* display: flex; */
  /* flex-direction: column; */
  /* background-color: #F2F4F7; */
  padding-top: 32px;
  /* margin-left: 64px; */
  /* margin-right: 64px; */
  margin-top: 32px;
  max-width: 1440px;
  padding-left: 64px;
  padding-right: 64px;
  margin: auto;
}

.main-blog-screen-contianer-latest-article {
  margin-left: 0px;
}

.main-blog-screen-container {
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 32px;
  margin-bottom: 16px;
  padding-left: 64px;
  padding-right: 64px;
}

.main-blog-filter-container {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  max-width: 100vw;
  box-sizing: border-box;
  margin: 0;
}

.main-blog-filter-container::-webkit-scrollbar {
  display: none;
}

.main-blog-filter-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Additional styling to ensure buttons fit within container */
.main-blog-filter-container button {
  flex-shrink: 0; /* Prevent buttons from shrinking */
  white-space: nowrap; /* Prevent text from wrapping */
}

@media (max-width: 768px) {
  .main-blog-filter-container {
    /* padding: 10px 16px;  */
  }

  .main-blog-screen-container {
    padding-left: 16px;
  }
}
@media (max-width: 1440px) {
  .main-blog-screen-top-container {
    /* margin-right: 32px; */
    /* margin-left: 32px; */
    padding-left: 16px;
    padding-right: 16px;
  }
}
