.free-gifts-cell-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: var(--color-white);
  padding: 32px;
  border-radius: 12px;
  width: 100%;
  max-width: 460px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  text-align: left;
  flex: 1 1 calc(33.33% - 40px);
  position: relative;
}

.image-container {
  width: 100%;
  height: auto;
  margin-top: 16px;
  margin-bottom: 16px;
}

.plan-text {
  color: var(--color-gray);
  font-size: 14px;
  margin-bottom: 16px;
}

.highlight-plan {
  color: var(--color-blue);
  font-weight: bold;
}

.gift-title {
  color: var(--color-blue);
  margin-bottom: 16px;
}

.highlight-gift {
  color: var(--color-purple);
}

.gift-description {
  color: var(--color-gray);
  font-size: 16px;
  margin-bottom: 32px;
  line-height: 1.6;
}

.free-gifts-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}
