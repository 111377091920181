.profile-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.top-section {
  padding-left: 24px;
  padding-top: 24px;
}

.profile-screen {
  display: flex;
  width: 100%;
  /* height: 100vh; */
  flex-grow: 1;
}

.tabs-menu {
  width: 426px;
  padding: 24px;
}

.tabs-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tabs-menu ul {
  border-radius: 16px;
  border: 1px solid #eaecf0;
  overflow: hidden;
}

.tabs-menu li {
  display: flex;
  align-items: center;
  padding: 24px 16px 24px 16px;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease;
}

.tabs-menu li img {
  margin-right: 12px;
  width: 24px;
  height: 24px;
}

.tabs-menu li.active {
  background-color: var(--color-light-purple);
  color: var(--color-purple);
}

.tabs-menu li:hover {
  background-color: var(--color-light-purple);
}

.tabs-menu li.active img {
  filter: brightness(0) saturate(100%) invert(32%) sepia(79%) saturate(3247%)
    hue-rotate(262deg) brightness(91%) contrast(102%);
}
.tab-content {
  flex-grow: 1;
  padding: 24px 24px 24px 8px;
  border-radius: 16px;
  align-items: center;
}

.BottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

@media (max-width: 1048px) {
  .tabs-menu {
    display: none;
  }

  .profile-screen {
    flex-direction: column;
  }

  .tab-content {
    padding: 16px;
  }
}
