.workout-schedule-cell {
  padding: 16px;
  margin-bottom: 16px;
  max-width: 100%;
}

.workout-schedule-title {
  display: flex;
  align-items: center;
  color: var(--color-blue);
  margin-bottom: 8px;
}

.workout-schedule-dot {
  width: 12px;
  height: 12px;
  background-color: var(--color-purple);
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.workout-schedule-description p {
  margin: 4px 0;
  color: var(--color-gray);
}
