.summary-card {
  padding: 24px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 448px;
  margin: auto;
}

.summary-title {
  margin-bottom: 20px;
}

.summary-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.mt-3 {
  margin-top: 16px;
}

.mt-4 {
  margin-top: 24px;
}

.payment-methods,
.delivery-methods {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}

.payment-methods img,
.delivery-methods img {
  height: 24px;
}

.primary-button {
  width: 100%;
  padding: 12px 0;
}

@media (max-width: 600px) {
  .summary-card {
    width: 100%;
    padding: 16px;
  }
}
