.secondary-button {
  width: 100%;
  background-color: var(--color-white);
  border: 1px solid var(--color-light-gray);
  border-radius: 40px;
  padding: 8px 40px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 800;
  line-height: 21px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-content {
  display: flex;
  padding: 0px;
  align-items: center;
}

.button-logo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.secondary-button:hover {
  background-color: rgb(238, 238, 238);
}

.secondary-button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(108, 84, 189, 0.4);
}

.secondary-button:active {
  background-color: var(--color-white);
}

.secondary-button:disabled {
  border: 0px;
  background-color: #ddd;
  cursor: not-allowed;
  color: var(--color-disabled);
}
