/* ProductCell.css */
.product-cell {
  border-radius: 16px;
  width: 350px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 16px;
}

.product-image-container {
  background: var(--color-super-light-gray);
  justify-content: center;
  border-radius: 16px;
  width: 350px;
  height: 350px;
}

.product-image {
  object-fit: contain;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.product-description {
  max-lines: 1;
  color: #667085;
  margin-bottom: 8px;
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-prices {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
  align-items: center;
}

.product-rating {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.rating-value {
  margin-left: 4px;
  margin-right: 8px;
}

.rating-reviews {
  color: #667085;
}

.product-button-content {
  /* width: ; */
}

.product-button-content {
  width: 100%;
  opacity: 0;
  transform: translateY(10px);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.product-cell:hover .product-button-content {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .product-button-content {
    opacity: 1;
    transform: translateY(0);
  }
}
