.program-details {
  display: flex;
  align-items: flex-start;
  border-radius: 16px;
  padding: 24px;
  max-width: 1404px;
  margin: auto;
  justify-content: center;
  gap: 32px;
}

.program-image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 50%;
}

.program-image-container {
  width: 100%;
  height: 426px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: var(--color-default-background);
  border-radius: 16px;
  position: relative;
}

.program-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
}

.program-stats {
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  position: absolute;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.stat-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  text-align: left;
  font-size: 14px;
  gap: 8px;
  justify-content: left;
}

.program-info-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  padding-left: 32px;
  justify-content: space-between;
  gap: 24px;
}

.program-info-section p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 16;
  -webkit-box-orient: vertical;
}

.program-stats-mobile {
  display: none;
}

.btn-widht {
  margin-top: auto;
  max-width: 264px;
}

.start-button:hover {
  background-color: var(--color-dark-purple);
}

.more-info-block {
  display: none;
  flex: 1;
  /* display: flex; */
  flex-direction: column;
  max-width: 100%;
  padding-left: 16px;
  justify-content: space-between;
  gap: 24px;
}

.desktop-only-block {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  justify-content: space-between;
  gap: 24px;
  height: 426px;
}

@media (max-width: 1268px) {
  .more-info-block {
    display: flex;
  }

  .desktop-only-block {
    display: none;
  }

  .program-details {
    flex-direction: column;
    padding: 16px;
    align-items: center;
  }

  .program-image-section {
    width: 100%;
    max-width: none;
  }

  .program-image-container {
    height: auto;
  }

  .program-info-section {
    max-width: 100%;
    padding-left: 0;
  }

  .program-stats {
    display: none;
    /* grid-template-columns: 1fr;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: 100%;
        text-align: left; */
    /* gap: 8px;
        margin-top: 16px;
        width: 100%;
        position: relative; */
  }

  .program-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }

  .program-stats-mobile {
    display: grid;
    gap: 8px;
    margin-top: 16px;
    grid-template-columns: 1fr;
  }

  .btn-width {
    max-width: 324px;
  }

  .stat-item {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (max-width: 804px) {
  .program-details {
    flex-direction: column;
    padding: 16px;
  }

  .program-image-container {
    width: 100%;
    height: auto;
  }

  .program-info-section {
    max-width: 100%;
    padding-left: 0;
  }

  .program-image {
    width: 100%;
    height: auto;
  }

  .program-stats {
    display: none;
  }

  .program-stats-mobile {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    margin-top: 16px;
  }
}
