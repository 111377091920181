.order-view {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.order-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: start;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.track-shipment {
  color: #9b59b6;
  text-decoration: none;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.order-table th,
.order-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.product-info {
  display: flex;
  align-items: center;
}

.ordered-product-image {
  max-width: 56px;
  height: 50px;
  margin-right: 10px;
}

.product-subinfo {
  text-overflow: ellipsis;
}

.order-summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.order-summary > div {
  width: 30%;
}

.orderd-max-width {
  width: 96px;
}

.order-table td {
  /* padding: 10px; */
  /* text-align: left; */
  /* border-bottom: 1px solid #ddd; */
  /* text-overflow: ellipsis; */
}

.order-total {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.order-total h3 {
  margin-top: 0;
  margin-bottom: 20px;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.summary-row.total {
  border-top: 1px solid #ddd;
  padding-top: 10px;
}
