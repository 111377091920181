.workout-screen-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin: auto;
}

video {
  transition: opacity 0.3s ease-in-out;
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.video-player-section {
  /* width: 70%; */
  width: 952px;
  height: 100%;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  padding-bottom: 32px;
}

.training-info {
  margin-top: 16px;
  width: 100%;
}

.training-info p {
  color: var(--color-blue);
}

.video-desc-section p {
  overflow-wrap: break-word;
}

.training-stats {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.stats-content-item {
  flex: 1;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.training-list-section {
  width: 30%;
  padding-right: 16px;
  padding-top: 36px;
  gap: 18px;
}

.training-list-section {
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
}

.task-image {
  width: 84px;
  height: 48px;
  object-fit: cover;
  margin-right: 12px;
  border-radius: 5px;
}

.workouts-tabs-content {
  margin-top: 32px;
}

.training-task {
  display: flex;
  align-items: center;
  padding: 13px;
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  border: 1.4px solid #eaecf0;
  transition: border 0.3s ease;
  margin-bottom: 8px;
}

.training-task.active {
  background-color: #fcfaff;
  border: 1.4px solid var(--color-primary);
}

.task-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.video-desc-section p {
  color: var(--color-blue);
}

.video-desc-section {
  padding-top: 16px;
}

.training-info h4 {
  color: var(--color-blue);
}

.task-info h4 {
  color: var(--color-blue);
  margin: 0;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}

.stats-content-item p {
  background-color: #ffffff;
  color: var(--color-blue);
}

.task-info p {
  margin: 0;
  padding-top: 8px;
  color: #667085;
  text-align: right;
}
.task-duration {
  display: flex;
  align-items: center;
  gap: 6px;
}

.play-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.element-icon {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.workout-web-description-container {
  display: flex;
}

.workout-mobile-description-container {
  display: none;
}

@media (max-width: 1376px) {
  .workout-screen-container {
    flex-direction: column;
    padding: 16px;
  }

  .video-player-section {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .training-info {
    margin-bottom: 32px;
    margin-top: 32px;
    /* margin: 16px 0; */
  }

  .training-stats {
    flex-direction: column;
    gap: 16px;
    margin-left: 0;
  }

  .training-list-section {
    width: 100%;
  }

  .training-task {
    padding: 16px;
    margin-bottom: 16px;
  }

  .task-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .task-info h4,
  .task-info p {
    text-align: left;
    width: 100%;
  }

  .task-image {
    height: 48px;
    width: 84px;
    /* display: none; */
  }

  .training-task {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 64px;
  }

  .task-info {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .task-duration {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .video-desc-section p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .video-desc-section {
    margin-left: 0px;
  }

  .workout-web-description-container {
    display: none;
  }

  .workout-mobile-description-container {
    display: block;
  }
}

.workout-tabmenu
  .p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem.p-highlight
  .p-menuitem-link {
  border-color: var(--color-purple);
  width: 100%;
  justify-content: center;
}

.workout-tabmenu .p-tabmenuitem a {
  width: 100%;
  justify-content: center;
  background-color: var(--color-default-background);
  color: var(--color-gray);
}

.workout-tabmenu .p-tabmenuitem {
  width: 50%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
