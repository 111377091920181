.offer-cell {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 100%;
  max-width: 384px;
  text-align: left;
  margin-bottom: 24px;
  position: relative;
}

.most-popular {
  background: radial-gradient(
    circle at top center,
    rgba(180, 110, 249, 0.2) 0%,
    rgba(255, 255, 255, 0) 65%
  );
}

.badge {
  background-color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.offer-original-price {
  color: var(--color-purple);
  /* font-size: 16px; */
  /* text-decoration: line-through; */
  margin-bottom: 36px;
  /* text-align: left; */
}

.offer-current-price {
  font-size: 32px;
  color: var(--color-blue);
  margin: 0;
  margin-bottom: 16px;
  text-align: left;
}

.offer-plan-duration {
  color: var(--color-gray);
}

.offer-plan-name {
  color: var(--color-navy);
  margin-bottom: 4px;
}

.offer-plan-subtext {
  font-size: 14px;
  color: var(--color-gray);
  margin-top: 8px;
  margin-bottom: 24px;
}

.offer-features {
  margin-top: 24px;
  width: 100%;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.icon.included {
  color: var(--color-purple);
  margin-right: 12px;
}

.icon.excluded {
  color: var(--color-error);
  margin-right: 12px;
}

.included-text {
  color: var(--color-navy);
  font-size: 14px;
}

.excluded-text {
  color: var(--color-error);
  font-size: 14px;
}

.offer-gift {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-super-light-gray);
  border-radius: 16px;
  padding: 12px;
}

.gift-description {
  display: flex;
  align-items: top;
  justify-content: center;
  margin-bottom: 4px;
}

.gift-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: var(--color-purple);
}

.gift-image {
  width: 70%;
  height: auto;
}

.gift-images {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .offer-cell {
    max-width: 100%;
    padding: 16px;
  }

  .offer-select-button {
    width: 100%;
    font-size: 14px;
  }

  .offer-current-price {
    font-size: 28px;
  }

  .gift-image {
    width: 80px;
  }
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  .offer-cell {
    max-width: 100%;
    padding: 16px;
    text-align: center;
    align-items: center;
  }

  .offer-current-price {
    font-size: 24px;
  }

  .offer-select-button {
    width: 100%;
  }

  .offer-gift {
    align-items: center;
  }

  .gift-image {
    width: 100px;
  }
}

.mobile-offer-cell {
  position: relative;
  display: flex;
  flex-direction: column;
}

.mobile-offer-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 100%;
  text-align: left;
  position: relative;
}

.mobile-most-popular {
  background: radial-gradient(
    circle at top center,
    rgba(180, 110, 249, 0.2) -5%,
    rgba(255, 255, 255, 0) 100%
  );
}

.mobile-plan-header {
  margin-bottom: 8px;
}

.mobile-plan-pricing {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 10px;
}

.mobile-gift-section {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: auto;
  gap: 10px;
  width: 100%;
}

.mobile-gift-description {
  display: flex;
  align-items: top;
  justify-content: center;
}

.mobile-features {
  margin: 16px 0;
}

.mobile-btn-content {
  margin-top: 10px;
  width: 100%;
}

.circle-gift-content {
  position: absolute;
  top: -30px;
  right: 10px;
  width: 120px;
  height: 120px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
}

.circle-gift-content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
