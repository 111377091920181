.account-content {
  padding: 24px;
  display: flex;
  background-color: var(--color-white);
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}

.section {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.section-left {
  width: 30%;
}

.section-left p {
  color: var(--color-gray);
  margin-top: 8px;
}

.edit-link {
  display: inline-flex;
  align-items: center;
  color: var(--color-purple);
  font-weight: 600;
  margin-top: 16px;
  text-decoration: none;
}

.edit-link svg {
  margin-left: 8px;
}

.section-right {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.spacer {
  /* padding: 64px 0px 64px 0px; */
  margin-left: 46px;
  margin-right: 46px;
  margin-top: 64px;
  margin-bottom: 64px;
}

@media (max-width: 1048px) {
  .account-content {
    padding: 16px;
  }

  .section {
    display: block;
    padding: 0px;
  }

  .section-left {
    padding-bottom: 36px;
    width: 100%;
  }

  .section-right {
    width: 100%;
  }
}
