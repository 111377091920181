.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: white;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  height: 64px;
  width: 100%;
  flex-wrap: nowrap;
}

.bottom-logo {
  display: flex;
  align-self: center;
}

.bottom-logo img {
  margin: 0;
  top: 50%;
  left: 50%;
  height: 54px;
  margin: -20px 0 0 0;
}

.bottom-links {
  display: flex;
  align-items: center;
  gap: 42px;
  padding: 24px;
  position: relative;
}

.bottom-links a {
  text-decoration: none;
  color: var(--color-blue);
}

.bottom-links a:hover {
  color: var(--color-primary);
}

@media (max-width: 914px) {
  .bottom {
    padding-left: 4px;
    padding-right: 4px;
  }
}

@media (max-width: 782px) {
  .bottom {
    flex-direction: column;
    align-items: center;
    height: auto;
    /* padding: 24px; */
    padding-left: 8px;
    padding-right: 8px;
    gap: 24px;
  }

  .bottom-logo {
    margin-bottom: 16px;
  }

  .bottom-links {
    flex-direction: row;
    gap: 5%;
    padding: 0;
  }
}
