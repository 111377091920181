.basket-list-content {
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 16px;
}

.basket-list-empty-content {
  width: 100%;
  height: 264px;
  display: flex;
  padding-left: 32px;
  padding-right: 32px;
  align-items: center;
  justify-content: center;
}
