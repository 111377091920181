.checkbox-wrapper {
  display: flex;
  align-items: center;
  border-radius: 7px;
}

.checkbox-input {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  cursor: pointer;
  accent-color: var(--color-purple);
}

.checkbox-label {
  color: #667085;
}

.checkbox-label.required {
  color: var(--color-error);
  text-decoration: underline;
}
