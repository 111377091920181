.register-container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.register-left {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 24px;
  position: relative;
}

.logo-container {
  position: absolute;
  top: 8px;
  left: 8px;
}

.form-container {
  width: 640px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: -16px; */
}

.register-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center; /* Dodane */
}

.register-form {
  width: 360px;
}

.register-right {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Zmienione z center na flex-start */
  overflow: hidden;
  flex-direction: column;
  background-color: var(--color-super-light-gray);
  border-top-left-radius: 64px;
  border-bottom-left-radius: 64px;
  position: relative;
}

.register-right-info {
  position: absolute;
  bottom: 64px;
  left: 64px;
  width: 647px;
  z-index: 10;
}

.register-right-info h2 {
  color: white;
}

.register-right-info p {
  color: white;
}

.promo-image {
  width: 60%;
  height: 70%;
  object-fit: cover;
}

.logo {
  height: 64px;
  padding-left: 24px;
  padding-top: 24px;
  justify-content: left;
  align-items: left;
}

.form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 16px;
}

.label {
  margin-top: 0px;
  margin-bottom: 24px;
  text-align: left;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 8px;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--color-light-gray);
}

.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}

.google-button,
.apple-button {
  width: 100%;
  padding: 12px;
  background-color: #f5f7fa;
  border: 1px solid var(--color-light-gray);
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-button img,
.apple-button img {
  width: 20px;
  margin-right: 8px;
}

.register-footer {
  text-align: center;
  margin-top: 16px;
}

.register-footer a {
  color: var(--color-purple);
  text-decoration: none;
}

.app-promo-section {
  text-align: left;
  margin: 32px;
}

.subheading {
  font-size: 18px;
  color: var(--color-gray);
  margin-bottom: 32px;
}

.app-store-buttons {
  display: flex;
  justify-content: left;
  gap: 20px;
}

.app-badge {
  width: 120px;
  cursor: pointer;
}

.partners-badge {
  margin-top: 16px;
}

@media (max-width: 1300px) {
  .register-container {
    padding-bottom: 24px;
  }

  .register-left {
    width: 100%;
    padding: 0px;
  }

  .form-container {
    margin-top: 96px;
    width: 70%;
  }

  .register-right {
    display: none;
  }
}
