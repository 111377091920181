.primary-button {
  width: 100%;
  background-color: var(--color-purple);
  color: var(--color-white);
  border: none;
  border-radius: 40px;
  padding: 8px 40px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 800;
  line-height: 21px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
}

.button-content {
  display: flex;
  padding: 24px;
  align-items: center;
}

.primary-button:hover {
  background-color: rgb(208, 160, 255);
}

.primary-button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(201, 183, 255, 0.4);
}

.primary-button:active {
  background-color: var(--color-purple);
}

.primary-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
  color: var(--color-disabled);
}
