.training-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 1404px;
  margin: auto;
}

.training-header {
  width: 100%;
  text-align: left;
  margin-bottom: 48px;
}

.training-header h2 {
  color: var(--color-blue);
}

.training-list {
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  justify-content: flex-start;
  max-width: 1404px;
  width: 100%;
}

@media (max-width: 1404px) {
  .training-container {
    margin: auto;
    width: 100%;
  }

  .training-list {
    justify-content: center;
  }
}
